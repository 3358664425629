import Link from 'next/link';

import type ProductType from '../../types/ProductType';
import WebpImage from '../layout/WebpImage';

import styles from './ProductTile.module.scss';

interface Props {
  product: ProductType;
  className?: string;
  onClick?: () => void;
}

const ProductTile: React.FC<Props> = ({ product, className, onClick }) => {
  return (
    <Link href={`/produkty/${product.slug}`}>
      <a
        onClick={onClick}
        className={[styles.productTile, className].join(' ')}
      >
        <div className={styles.productLayer}>
          <div className={styles.productLayerInside}>
            <figure className={styles.productImage}>
              {product.thumb_image_url && (
                <WebpImage
                  imageUrl={product.thumb_image_url}
                  webpImage={product.webp_thumb_image_url}
                  alt={product.name}
                />
              )}
            </figure>
          </div>
        </div>
        <div className={styles.productTexts}>
          <h3 className={styles.productName}>{product.name}</h3>
          <div className={styles.productDescription}>
            {product.category.name}
          </div>
        </div>
      </a>
    </Link>
  );
};
export default ProductTile;
