import Link from 'next/link';

import WebpImage from '../layout/WebpImage';

import styles from './HomeBanner.module.scss';

const HomeBanner: React.FC = () => {
  return (
    <div className={styles.homeBanner}>
      <div className="container">
        <Link href="/kontakt">
          <a className={styles.homeBannerLink}>
            <span className={styles.bannerImageWrapper}>
              <WebpImage
                imageUrl="/images/banner.jpg"
                webpImage="/images/banner.webp"
                alt=""
              />
            </span>
            <span className={styles.bannerImageMobileWrapper}>
              <WebpImage
                imageUrl="/images/banner-mobile.jpg"
                webpImage="/images/banner-mobile.webp"
                alt=""
              />
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default HomeBanner;
