import { SettingsType } from '../../services/settingsService';
import type ArticleType from '../../types/ArticleType';
import ArticleTile from '../articles/ArticleTile';
import HomeArticleRow from './HomeArticleRow';

import styles from './HomeArticles.module.scss';

interface Props {
  articles: Array<ArticleType>;
  appSettings: SettingsType;
}

const HomeArticles: React.FC<Props> = ({ articles, appSettings }) => {
  const [leftArticle, ...rightArticles] = articles;
  return (
    <div className={styles.homeArticles}>
      <div className="container">
        <h2 className="pageHeading">
          {appSettings.home_articles_heading || 'Ostatnie aktualności'}
        </h2>

        <div className={styles.homeArticlesCols}>
          {!!leftArticle && <ArticleTile article={leftArticle} />}

          <div className={styles.articlesList}>
            {rightArticles.map((article) => (
              <HomeArticleRow key={article.id} article={article} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeArticles;
