import type { GetServerSideProps, NextPage } from 'next';

import HomeArticles from '../components/home/HomeArticles';
import HomeBanner from '../components/home/HomeBanner';
import HomeProducts from '../components/home/HomeProducts';
import HomeSlider from '../components/home/HomeSlider';
import HomeTextBoxes from '../components/home/HomeTextBoxes';
import SimilarProducts from '../components/product/SimilarProducts';
import articlesService from '../services/articlesService';
import productsService from '../services/productsService';
import { SettingsType } from '../services/settingsService';
import sliderService from '../services/sliderService';
import textBlocksService from '../services/textBlocksService';
import styles from '../styles/Home.module.css';
import type ArticleType from '../types/ArticleType';
import type CategoryType from '../types/CategoryType';
import type ProductType from '../types/ProductType';
import type SlideType from '../types/SlideType';

export const getServerSideProps: GetServerSideProps = async () => {
  const { products } = await productsService.getProducts();
  const { articles } = await articlesService.getArticles(1, 5);
  const { categories } = await productsService.getCategories();
  const { slides } = await sliderService.getSlides();
  const textBlocks = (
    await textBlocksService.getTextBlocks()
  ).text_blocks.reduce(
    (hash, item) => ({ ...hash, [item.name]: item.content }),
    {}
  );

  return {
    props: {
      articles,
      categories,
      products,
      slides,
      textBlocks,
    },
  };
};

interface Props {
  articles: Array<ArticleType>;
  categories: Array<CategoryType>;
  products: Array<ProductType>;
  slides: Array<SlideType>;
  textBlocks: Record<string, string>;
  appSettings: SettingsType;
}

const Home: NextPage<Props> = ({
  articles,
  categories,
  products,
  slides,
  textBlocks,
  appSettings,
}) => {
  return (
    <div className={styles.container}>
      <HomeSlider slides={slides} />

      <HomeProducts categories={categories} textBlocks={textBlocks} />

      <HomeTextBoxes textBlocks={textBlocks} />
      <SimilarProducts products={products} />
      <HomeArticles articles={articles} appSettings={appSettings} />
      <HomeBanner />
    </div>
  );
};

export default Home;
