import { EffectCoverflow, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type ProductType from '../../types/ProductType';
import Button from '../layout/Button';
import ProductTile from './ProductTile';

import styles from './SimilarProducts.module.scss';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

interface Props {
  products: Array<ProductType>;
}

const SimilarProducts: React.FC<Props> = ({ products }) => {
  const breakpoints = {
    768: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
  };
  return (
    <div className={styles.similarProducts}>
      <div className="container">
        <div className={styles.similarProductsHeading}>
          <h2 className="pageHeading">Wybrane produkty</h2>
          <Button className={styles.moreButton} href="/katalog">
            Zobacz więcej
          </Button>
        </div>

        <div className={styles.productsList}>
          <Swiper
            slidesPerView="auto"
            effect="coverflow"
            spaceBetween={0}
            navigation={true}
            loop
            coverflowEffect={{
              rotate: 30,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            modules={[EffectCoverflow, Navigation]}
            breakpoints={breakpoints}
            className="productsSwiper"
          >
            {products.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductTile product={product} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Button className={styles.moreButton} href="/katalog">
          Zobacz więcej
        </Button>
      </div>
    </div>
  );
};

export default SimilarProducts;
