import Link from 'next/link';

import dayjs from 'dayjs';

import type ArticleType from '../../types/ArticleType';
import WebpImage from '../layout/WebpImage';

import styles from './HomeArticleRow.module.scss';

interface Props {
  article: ArticleType;
}

const HomeArticleRow: React.FC<Props> = ({ article }) => {
  return (
    <div className={styles.articleRow}>
      <div className={styles.articleDate}>
        {dayjs(article.published_at).format('DD.MM')}
      </div>
      <h4 className={styles.articleTitle}>
        <Link href={`/blog/${article.slug}`}>
          <a>{article.title}</a>
        </Link>
      </h4>
      <div className={styles.articleImage}>
        {!!article.small_image_url && (
          <Link href={`/blog/${article.slug}`}>
            <a>
              <WebpImage
                imageUrl={article.small_image_url}
                webpImage={article.webp_thumb_image_url}
                alt={article.title}
              />
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};

export default HomeArticleRow;
