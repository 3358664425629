import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type SlideType from '../../types/SlideType';
import Button from '../layout/Button';
import WebpImage from '../layout/WebpImage';

import styles from './HomeSlider.module.scss';

import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface Props {
  slides: Array<SlideType>;
}

const HomeSlider: React.FC<Props> = ({ slides }) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return (
        '<span class="' +
        className +
        '">' +
        `0${index + 1}`.slice(-2) +
        '</span>'
      );
    },
  };

  return (
    <div className={styles.homeSlider}>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        navigation={true}
        pagination={pagination}
        effect="creative"
        speed={600}
        loop={true}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[EffectCreative, Pagination, Navigation, Autoplay]}
        className="homeSwiper"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 5000,
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className={styles.homeSlide}>
              {slide.mobile_image_url && (
                <>
                  <WebpImage
                    imageUrl={slide.mobile_image_url}
                    webpImage={slide.webp_mobile_image_url}
                    wrapperClassName={styles.homeSlideMobileImageWrapper}
                    alt={slide.heading_text}
                  />
                </>
              )}
              {slide.image_url && (
                <>
                  <WebpImage
                    imageUrl={slide.image_url}
                    webpImage={slide.webp_image_url}
                    wrapperClassName={styles.homeSlideImageWrapper}
                    alt={slide.heading_text}
                  />
                </>
              )}
              <div className={styles.homeSlideText}>
                <h2 className={styles.homeSlideHeading}>
                  {slide.heading_text}
                </h2>
                {!!slide.subheading_text && (
                  <h3 className={styles.homeSlideSubheading}>
                    {slide.subheading_text}
                  </h3>
                )}
                {!!slide.button_text && (
                  <div className={styles.homeSlideActions}>
                    <Button href={slide.button_url}>{slide.button_text}</Button>
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomeSlider;
