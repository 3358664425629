import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type CategoryType from '../../types/CategoryType';
import Button from '../layout/Button';
import HomeCategory from './HomeCategory';

import 'swiper/css';
import 'swiper/css/navigation';

import styles from './HomeProducts.module.scss';

interface Props {
  categories: Array<CategoryType>;
  textBlocks: Record<string, string>;
}

const HomeProducts: React.FC<Props> = ({ categories, textBlocks }) => {
  const breakpoints = {
    640: {
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 'auto' as const,
      spaceBetween: 30,
    },
  };

  return (
    <div className={styles.homeProducts}>
      <div className={styles.homeProductsLayout}>
        <div className={styles.homeProductsText}>
          <div
            dangerouslySetInnerHTML={{
              __html: textBlocks['Home-OpisKategorii'],
            }}
          />

          <div className={styles.homeProductsActions}>
            <Button href="/katalog">Pełna oferta</Button>
          </div>
        </div>

        <div className={styles.homeProductsSlider}>
          <Swiper
            slidesPerView="auto"
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            breakpoints={breakpoints}
            className="categoriesSwiper"
          >
            {categories.map((category) => (
              <SwiperSlide key={category.id}>
                <HomeCategory key={category.id} category={category} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeProducts;
