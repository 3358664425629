import Link from 'next/link';

import dayjs from 'dayjs';
import TextTruncate from 'react-text-truncate';
import striptags from 'striptags';

import type ArticleType from '../../types/ArticleType';
import WebpImage from '../layout/WebpImage';

import styles from './ArticleTile.module.scss';

interface Props {
  article: ArticleType;
  className?: string;
}

const ArticleTile: React.FC<Props> = ({ article, className }) => {
  return (
    <div className={[styles.articleTile, className].join(' ')}>
      <Link href={`/blog/${article.slug}`}>
        <a>
          <figure className={styles.articleImage}>
            {article.small_image_url && (
              <WebpImage
                imageUrl={article.small_image_url}
                webpImage={article.webp_thumb_image_url}
                alt={article.title}
              />
            )}
          </figure>
        </a>
      </Link>

      <div className={styles.articleDate}>
        {dayjs(article.published_at).format('DD.MM.YYYY, HH:mm')}
      </div>
      <h3 className={styles.articleTitle}>
        <Link href={`/blog/${article.slug}`}>
          <a>{article.title}</a>
        </Link>
      </h3>

      {article.content ? (
        <TextTruncate
          line={3}
          element="div"
          containerClassName={styles.articleText}
          truncateText="…"
          text={striptags(article.content).substring(0, 500)}
          textTruncateChild={
            <Link href={`/blog/${article.slug}`}>
              <a>Czytaj dalej</a>
            </Link>
          }
        />
      ) : null}
    </div>
  );
};
export default ArticleTile;
