import React from 'react';

import Button from '../layout/Button';
import WebpImage from '../layout/WebpImage';

import styles from './HomeTextBoxes.module.scss';

interface Props {
  textBlocks: Record<string, string>;
}
const HomeTextBoxes: React.FC<Props> = ({ textBlocks }) => {
  return (
    <div className={styles.homeBoxes}>
      <div className={styles.homeBox}>
        <div className={styles.homeBoxImage}>
          <WebpImage
            imageUrl="/images/home-box-1.jpg"
            webpImage="/images/home-box-1.webp"
            alt=""
          />
        </div>
        <div className={styles.homeBoxText}>
          <WebpImage
            imageUrl="/images/logo.png"
            webpImage="/images/logo.webp"
            alt=""
          />

          <div
            dangerouslySetInnerHTML={{
              __html: textBlocks['Home-Opis1'],
            }}
          />

          <div className={styles.homeBoxActions}>
            <Button href="/katalog">pokaż ofertę »</Button>
          </div>
        </div>
      </div>
      <div className={styles.homeBox}>
        <div className={styles.homeBoxImage}>
          <WebpImage
            imageUrl="/images/home-box-2.jpg"
            webpImage="/images/home-box-2.webp"
            alt=""
          />
        </div>
        <div className={styles.homeBoxText}>
          <div className={styles.homeBoxHeading}>
            <h2>wysokiej jakości produkty do samochodów ciężarowych</h2>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: textBlocks['Home-Opis2'],
            }}
          />

          <div className={styles.homeBoxActions}>
            <Button href="/katalog">dopasuj olej</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTextBoxes;
