import Link from 'next/link';

import type CategoryType from '../../types/CategoryType';
import WebpImage from '../layout/WebpImage';

import styles from './HomeCategory.module.scss';

interface Props {
  category: CategoryType;
}

const HomeCategory: React.FC<Props> = ({ category }) => {
  return (
    <Link href={`/katalog/${category.slug}`}>
      <a className={styles.homeCategory}>
        {category.image_url && (
          <WebpImage
            imageUrl={category.image_url}
            webpImage={category.webp_image_url}
            alt={category.name}
          />
        )}
        <h3>{category.name}</h3>
        <div className={styles.homeCategoryIcon}>
          {category.logo_url && (
            <WebpImage
              imageUrl={category.logo_url}
              webpImage={category.webp_logo_url}
              className={styles.homeSlideImage}
              alt={category.name}
            />
          )}
        </div>
      </a>
    </Link>
  );
};

export default HomeCategory;
